<template>
  <div class="settings">
    <div class="dashboard-content py-4">
      <div class="settings__container p-4 animate__animated animate__lightSpeedInLeft animate__delay-1s">
        <h2>Personal Information</h2>
        <hr class="horizontal_rule mb-5">
        <PersonalInfo
          :user="user"
          :errors="errors"
          @makeEdit="$bvModal.show('edit_profile')"
          @btnTriggered="gotoPaymentPage" 
          @input="uploadPicture"
        />
      </div>
    </div>
    <EditProfile :edit-data="user" />
  </div>
</template>

<script>
import PersonalInfo from "@/components/settings/PersonalInfo.vue";
import EditProfile from "@/components/settings/EditProfile.vue";
import setErrorMixin from "@/mixins/setErrorMixin";

export default {
  components: {
    PersonalInfo,
    EditProfile,
  },
  mixins: [setErrorMixin],
  data() {
    return {
      user: {
        name: "Abdullah Muahz",
        gender: "female",
        email: "mail@email.com",
        phone: "+94456768797879",
        business: "Supertowers Consult",
        dob: "23/12/1978",
        address: "24th floor Ivory towers UAE",
        website: "https://www.supertowers.com",
        },
        errors: [],
    };
  },
  methods: {
    gotoPaymentPage() {
      // console.log('goto payment page')
      this.$router.replace('settings/payment');
    },
    uploadPicture(value) {
      const formData = new FormData()
      formData.append("profile_picture", value);

      this.$store
        .dispatch("settings/updateProfilePicture", formData)
        .then(() => {
            //there is no action needed here.
        })
        .catch((err) => {
          let data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
        })
    }
  }
};
</script>
<style lang="scss" scoped>
.settings {
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }
  &__container {
    background-color: $body-bg;
  }
}
</style>
