<template>
  <div class="card profile-information m-rounded-12 pt-2 pb-4 px-3">
    <div :class="headerClasses">
      <h3 v-if="title.length">{{ title }}</h3>
      <div v-else>
        <slot name="title"> </slot>
      </div>
      <div>
        <b-button v-if="secondaryBtn" variant="light" class="mr-3" @click="btnTriggered">
          <EditIcon variant="light" /><span class="ml-2">{{ secondaryBtnText }}</span>
        </b-button>
        <b-button variant="light-blue" @click="makeEdit()">
          <EditIcon /><span class="ml-2">Edit Profile</span>
        </b-button>
      </div>
    </div>
    <div class="profile-information__body py-3 px-2 m-fs-14">
      <div v-for="(data, key, i) in profileData" :key="i" class="mb-3">
        <strong class="d-block mb-2">{{ key | capitalize }}</strong>
        <span>{{ data }}</span>
      </div>
      <slot name="body"> </slot>
    </div>
  </div>
</template>

<script>
import EditIcon from "../svg/EditIcon.vue";
export default {
  components: {
    EditIcon,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    secondaryBtn: {
      type: Boolean,
      default: false,
    },
    secondaryBtnText: {
      type: String,
      default: "",
    },
  },
  computed: {
    headerClasses() {
      return [
        "profile-information__header",
        "d-flex",
        "justify-content-between",
        "align-items-center",
        { "border-bottom": this.title.length },
        "p-2",
      ];
    },
  },
  methods: {
    makeEdit() {
      this.$emit("makeEdit");
    },
    btnTriggered() {
      this.$emit("btnTriggered");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-information {
  color: $black-tint-3;
  width: 70%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.1875rem;
  }
  .btn {
    height: 38px;
    @include media-breakpoint-down(sm) {
      span {
        display: none;
      }
    }
  }
  .btn-light-blue {
    color: $primary-color;
    background-color: rgb(8, 86, 157, 0.1);
  }
  .btn-light {
    color: $grey-tint-6;
    background-color: $grey-tint-4;
    border: 1px solid $grey-tint-5;
  }
}
</style>
