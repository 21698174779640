<template>
  <profile-information
    class="personal-info"
    :secondary-btn="true"
    secondary-btn-text="Update Payment Method"
    @makeEdit="makeEdit"
    @btnTriggered="btnTriggered"
  >
    <template #title>
      <div class="personal-info__avatar">
        <input ref="file" type="file" accept="image/*" class="d-none" @change="change">
        <div class="personal-info__image-group">
          <img :src="src" alt="avatar" class="personal-info__image rounded">
          <button @click="browse()" class="personal-info__image-icon rounded d-flex justify-content-center align-items-center">
            <ImageUploader />
          </button>
          <b-alert
            v-for="(err, i) in errors"
            :key="i"
            v-model="err.visible"
            class="mt-4"
            variant="danger"
            show
            dismissible
          >
            {{ err.message }}
          </b-alert>
        </div>
      </div>
    </template>
    <template #body>
      <b-row class="mb-3">
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Full Name</strong>
          <span>{{ user.name }}</span>
        </b-col>
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Gender</strong>
          <span>{{ user.gender | capitalize }}</span>
        </b-col>
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Email Address</strong>
          <span>{{ user.email | capitalize }}</span>
        </b-col>
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Phone Number</strong>
          <span>{{ user.phone | capitalize }}</span>
        </b-col>
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Business Owned</strong>
          <span>{{ user.business | capitalize }}</span>
        </b-col>
        <b-col sm="12">
          <strong class="d-block mb-1 mt-3">Company's Address</strong>
          <span>{{ user.address | capitalize }}</span>
        </b-col>
      </b-row>
    </template>
  </profile-information>
</template>

<script>
import ProfileInformation from "./ProfileInformation.vue";
import ImageUploader from "../svg/ImageUploader.vue"
import DefaultAvatar from "../../assets/img/avatar.png";


export default {
  components: {
    ProfileInformation,
    ImageUploader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    errors: {
      type: Array,
      default: () => []
    },
    value: File
  },
  data() {
    return {
      src: DefaultAvatar,
      file: null,
      skills: [
        {
          class: "fab fa-react",
          name: "React",
          tags: [2],
          level: "intermediate",
        },
        {
          class: "fa-brands fa-node-js",
          name: "Node.js",
          tags: [1],
          level: "junior",
        },
        {
          class: "fa-brands fa-vuejs",
          name: "Vue",
          tags: [2],
          level: "senior",
        },
      ],
    };
  },
  computed: {
    avatar() {
      return this.user?.avatar || require('@/assets/img/avatar.png')
    }
  },
  methods: {
    makeEdit() {
      this.$emit("makeEdit");
    },
    btnTriggered() {
      this.$emit("btnTriggered");
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      this.file = e.target.files[0];

      this.$emit('input', this.file); 

      let reader = new FileReader();

      reader.readAsDataURL(this.file);

      reader.onload = (e) => {
        this.src = e.target.result;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.personal-info {
  h4 {
    font-size: 0.875rem;
    font-weight: 600;
  }
  &__image-group{
    position: relative;
    display: inline-block
  }
  &__image {
    width: 64px;
    height: 64px;
  }
  &__image-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); 
    border: none;
    background: none;
  }
}
</style>
