<template>
  <primary-modal ref="editProfile" modal-id="edit_profile" title="Edit Profile">
    <div class="edit-profile p-3">
      <b-form ref="editProfileForm" @submit="onSubmit">
        <b-form-group label="Full name" label-for="name">
          <b-form-input
            id="name"
            v-model="user.name"
            class="edit-profile_input"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Legal Entity" label-for="entity">
          <b-form-input
            id="entity"
            v-model="user.business"
            class="edit-profile_input"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email" label-for="email">
          <b-form-input
            id="email"
            v-model="user.email"
            class="edit-profile_input"
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Phone number" label-for="phone">
          <b-form-input
            id="phone"
            v-model="user.phone"
            class="edit-profile_input"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Business Address" label-for="address">
          <b-form-input
            id="address"
            v-model="user.address"
            class="edit-profile_input"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Website URL" label-for="website">
          <b-form-input
            id="website"
            v-model="user.website"
            class="edit-profile_input"
          ></b-form-input>
        </b-form-group>
        <b-button block type="submit" variant="primary" class="mt-5">Submit</b-button>
      </b-form>
    </div>
  </primary-modal>
</template>

<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
export default {
  name: "EditProfile",
  components: {
    PrimaryModal,
  },
  props: {
    editData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: this.editData,
    };
  },
  watch: {
    editData(obj) {
      this.user = obj;
    },
  },
  methods: {
    onSubmit() {
      console.log("onSubmit");
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-profile {
  &_input {
    font-size: 14px;
    color: #afb9c6;
  }
}
</style>
